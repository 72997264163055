import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
    MDBBtn,
    MDBContainer,
    MDBIcon,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarToggler,
    MDBInputGroup
} from 'mdb-react-ui-kit'
import { selectMiniLeftNav, selectShowLeftnav, setMiniLeftNav, setShowLeftnav, setZipCode } from '../../../redux/appSlice';
import appConfig from '../../../configs/App.config';
import './Topbar.css';
import UserTray from '../userTray/UserTray';
import NotificationsTray from '../notificationsTray/NotificationsTray';
import { selectTokenData } from '../../../redux/authSlice';
import { useNavigate } from 'react-router-dom';

const Topbar = () => {
    const userData = useSelector(selectTokenData);
    const [searchTerm, setSearchTerm] = useState('');
    const [userType, setUserType] = useState('');
    const dispatch = useDispatch();
    const toggleShow = () => {
        dispatch(setShowLeftnav());
    };
    const isMiniLeftnav = useSelector(selectMiniLeftNav);
    const showLeftNav = useSelector(selectShowLeftnav);
    const navigate = useNavigate();

    const [rotate, setRotate] = useState("rotate-0 text-light menu-colaps p-0");
 
    const changeRotate = () => {
        console.log("you just clicked");
        if (rotate !== "rotate-0 text-light menu-colaps p-0") setRotate("rotate-0 text-light menu-colaps p-0");
        else setRotate("rotate-180 text-light menu-colaps p-0");
    };

    useEffect(() => {
        setUserType(userData.USER_TYPE);
    }, [userData]);

    const collapseHandler = () => {
        if (!showLeftNav) {
            dispatch(setShowLeftnav());
        }
        dispatch(setMiniLeftNav());
    }

    const searchOnchange = (e) => {
        setSearchTerm(e.target.value);
    }

    const doSearch = () => {
        if (isMiniLeftnav) {
            dispatch(setMiniLeftNav());
            return;
        }

        // dispatch(setZipCode(searchTerm));
        navigate('/search/'+searchTerm);
        setSearchTerm("");
    }

    return (
        <>
            <MDBNavbar expand='lg' light className='top-bar p-0'>
                <MDBContainer fluid>
                    <MDBNavbarNav className="d-flex flex-row align-items-center w-auto  top-bar-brand">
                        <MDBNavbarToggler
                            type='button'
                            className={`bg-light text-primary ${!showLeftNav ? 'hide' : ''}`}
                            aria-label='Toggle navigation'
                            onClick={toggleShow}
                        >
                            <MDBIcon icon='bars' fas />
                        </MDBNavbarToggler>
                        <div className={`custome-brand ${isMiniLeftnav ? 'custome-brand-mini' : ''}`} >
                            <MDBNavbarBrand children="div"
                                style={{
                                    justifyContent: "center"
                                }}>
                                <img
                                    src='/images/logo-ejdh.png'
                                    height='30'
                                    alt=''
                                    loading='lazy'
                                />
                            </MDBNavbarBrand>

                        </div>
                        <MDBBtn color='link' className={rotate}
                        onClick={()=>{
                            collapseHandler();
                            changeRotate();
                        }} >
                          <MDBIcon fas icon="angle-left"className='text-primary' />
                        </MDBBtn>
                        <h5 className='nav-title'>
                            {appConfig.title}
                        </h5>

                    </MDBNavbarNav>
                    <MDBNavbarNav className="d-flex flex-row justify-content-end w-auto">
                        <UserTray />
                    </MDBNavbarNav>

                </MDBContainer>
            </MDBNavbar>
        </>
    )
}

export default Topbar
