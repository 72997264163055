import React, { useEffect, useState } from 'react'
import { Link, NavLink, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    MDBInput,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardHeader,
    MDBCardFooter,
    MDBIcon,
} from 'mdb-react-ui-kit';
import './Login.css'
import { login, logout, selectToken } from '../../../redux/authSlice';
import CircularProgress from '../../common/progress/Circular';
import jwt_decode from 'jwt-decode';
import appConfig from '../../../configs/App.config';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faHome, faHomeAlt, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
// import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
// import styled from '@emotion/styled';

const Login = () => {
    // const count = useSelector(selectCount);
    const dispatch = useDispatch();
    const [showError, setShowError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [gotoDashboard, setGotoDashboard] = useState(false);
    //const [ticker, setTicker] = useState(null);
    const [token, setToken] = useState(null);
    const [ticks, setTicks] = useState(0);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [enableLogin, setEnableLogin] = useState(false);
    const [loginProcess, setLoginProcess] = useState(false);
    // const errMsg = 'Invalid username or password. Please try again.';
    let ticker = null;

    const token$ = useSelector(selectToken);
    // let ticks = 0;

    useEffect(() => {
        const ticker = setInterval(() => {
            const elm = document.getElementById('username');
            if (elm) {
                elm.focus();
                clearInterval(ticker);
            }
        }, 1000);
    }, []);

    useEffect(() => {
        if (username && password) {
            setEnableLogin(true);
        } else {
            setEnableLogin(false);
        }
    }, [username, password]);

    useEffect(() => {
        console.log('token$', token$);
        // debugger
        if (token$) {
            window.location.replace("/");
        }
    }, [token$]);

    const doLogin = (e) => {
        e.preventDefault();
        if (!username || !password) {
            setErrMsg('Username and Password are required.');
            setShowError(true);
            return;
        }

        setLoginProcess(true);
        fetch(`${appConfig.apiBaseUrl}/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                USER_NAME: username,
                PASSWORD: btoa(password),
                USER_TYPE: 'CBO-SUPER-USER'
            }),
        }).then((response) => {
            return response.json();
        }).then((data) => {
            if (data.statusCode >= 400) {
                setErrMsg(data.errMessage);
                setShowError(true);
                setLoginProcess(false);
            } else {
                const tokenData = jwt_decode(data.token);
                dispatch(login({
                    token: data.token,
                    tokenData: tokenData,
                }));
                // setIsSuccess(true);
                // setGotoDashboard(true);
                // setLoginProcess(false);
            }
        }).finally(() => {
            setLoginProcess(false);
        });
    }

    return (
        <div className='login-bg'>
        <div className='login-form'>
            <div className="login-form-container">
                <MDBCard alignment='center pt-3 pb-3'>
                    <MDBCardBody>
                        <MDBCardTitle>
                            <div className="login-form-header">
                                <img src="/images/logo-ejdh.png" className='img-fluid w-50' alt="Admin EJDAP" />
                                <h6 className='mt-4'>Admin Login</h6>
                            </div>
                        </MDBCardTitle>
                        <form onSubmit={doLogin}>
                            <MDBCardText tag={"div"}>
                                <div className="login-form-body">
                                    <div className='my-3'>
                                        <MDBInput
                                            label='Username'
                                            id='username'
                                            type='text'
                                            onChange={(e) => {
                                                setUsername(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className='my-3'>
                                        <MDBInput
                                            label='Password'
                                            id='password'
                                            type='password'
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </MDBCardText>
                            <div className="my-3 d-grid gap-2 col-6 mx-auto w-75">
                                <MDBBtn type='submit' disabled={!enableLogin || loginProcess} className='mb-1 btn btn-primary form-btn'>
                                    {loginProcess && <MDBIcon fas icon="spinner" className='me-2 fa-spin' /> }
                                    LOGIN
                                </MDBBtn>
                                <MDBBtn color='link' rippleColor='dark' onClick={(e) => {
                                    e.preventDefault();
                                    window.location.replace("/forgot-password");
                                }}>
                                    Forgot Password?
                                </MDBBtn>
                            </div>
                        </form>
                        {showError &&
                            <div className="error-block">
                                <p>{errMsg}</p>
                            </div>}
                    </MDBCardBody>
                </MDBCard>
            </div>
            {/* {showError && <Navigate to={appConfig.publicSiteUrl} replace={true} />} */}
            {showError && console.log('showError'+ showError)}
            {/* {showError && window.location.replace(appConfig.publicSiteUrl)} */}
            {/* {isSuccess && console.log('isSuccess:'+ isSuccess)} */}
            {isSuccess && <Navigate to="/auth" replace={true} />}
            {/* {gotoDashboard && console.log('gotoDashboard:'+ gotoDashboard)} */}
            {gotoDashboard && <Navigate to="/dashboard" replace={true} />}
        </div>
        </div>
    )
}

export default Login;
