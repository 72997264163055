const appConfig = {
    title: 'Environmental Justice Data Action Portal',
    // publicSiteUrl: 'http://localhost:3001',
    publicSiteUrl: 'https://www.ejdap.org',
    // publicSiteUrl: 'http://appdev.orchv.com',
    apiBaseUrl: 'https://appsrv-ejdap-prd.azurewebsites.net',
    // apiBaseUrl: 'http://localhost:8080', 
    maxUserNameLength: 15,
    // App Insights
    instrumentationKey:'2a330d45-f8c8-4c5b-9c55-701dffbe9592',
    connectionString:'InstrumentationKey=2a330d45-f8c8-4c5b-9c55-701dffbe9592;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/',

    // Toster config
    toastConfig: {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    },
};

export default appConfig;
