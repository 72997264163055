import { useEffect, useState } from 'react'
import {
    MDBBtn,
    MDBIcon,
    MDBTable,
    MDBTableBody,
    MDBBadge,
    MDBInputGroup,
    MDBListGroup,
    MDBListGroupItem
} from 'mdb-react-ui-kit';
import { toast } from 'react-toastify';
import './Dashboard.css'
import { useSelector } from 'react-redux';
import { selectToken } from '../../../redux/authSlice';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin} from '../../../services/appInsights';
import apis from '../../../services/apis';
import appConfig from '../../../configs/App.config';

const Dashboard = () => {
    const [cboTerm, setCboTerm] = useState("");
    const [cboData, setCboData] = useState([]);
    const [selectedCBO, setSelectedCBO] = useState({});
    const [gettingUserAccess, setGettingUserAccess] = useState(false);
    const [gettingUsers, setGettingUsers] = useState(false);
    const token$ = useSelector(selectToken);

    useEffect(() => {
        getCBOs();
    }, [token$])

    const getCBOs = () => {
        setGettingUsers(true);
        apis.setToken(token$).getCBOs(cboTerm).then((res) => {
            setCboData(res.length? res : []);
        }).catch((error) => {
            console.log("error", error);
        }).finally(() => {
            setGettingUsers(false);
        })
    }

    const formSubmit = (e) => {
        e.preventDefault();
        getCBOs();
    }

    const getCBODetails = (id) => {
        setSelectedCBO(cboData[id]);
        console.log("selectedCBO", cboData[id]);
    }

    const getName = (name) => {
        if (name.length < 20) {
            return name;
        }
        return name.substring(0, 20) + '...';
    }

    const getDateTime = (date) => {
        if (date === null) {
            return '';
        }
        return new Date(date).toLocaleString();
    }

    const sohwAsUser = () => {
        setGettingUserAccess(true);
        apis.setToken(token$).loginAsUser(selectedCBO.OBJID).then((res) => {
            console.log("res", res.statusCode);
            if (res.statusCode >= 400) {
                console.log("Show toster");
                toast.error('Failed to login as user', appConfig.toastConfig);
            }else{
                // open into new window/tab
                window.open(`${appConfig.publicSiteUrl}/auth/verify#token=${res.token}&m=R`, '_blank');
            }
        }).catch((error) => {
            console.log("error", error);
            toast.error('Failed to login as user', appConfig.toastConfig);
        }).finally(() => {
            setGettingUserAccess(false);
        })
    }

    const termOnChange = (e) => {
        setCboTerm(e.target.value);
    }

    return (
        <>
            <div className='row clearfix px-1'>
                <div className='col-3'>
                    <div className='card p-3 container-menu'>
                    <form onSubmit={formSubmit}>
                    <MDBInputGroup className='mb-3' color='dark' textBefore={"CBO"}>
                        <input className='form-control' placeholder="Search" type='text' onChange={termOnChange} />
                        <MDBBtn outline color='dark' type='submit'>
                            <MDBIcon fas icon='search' />
                        </MDBBtn>
                    </MDBInputGroup>
                    </form>
                    {gettingUsers && <h3 className='nodata-placeholder'>
                        <MDBIcon fas icon="spinner" className='fa-spin' /> Loading CBOs    
                    </h3>}
                    {(!gettingUsers && cboData.length>0) && <MDBListGroup light style={{
                        overflowY: 'auto',
                    }}>
                        {cboData.map((item, index) => {
                            return <MDBListGroupItem className="border-bottom border-1 px-3 py-2"
                                title={item.FULLNAME} key={item.ID} tag={"button"}
                                onClick={() => { getCBODetails(index) }} active={item.ID === selectedCBO.ID} action noBorders>
                                <MDBIcon fas icon="angle-double-right" className='' /> {getName(item.FULLNAME)}
                            </MDBListGroupItem>
                        })}

                    </MDBListGroup>
                    }
                    {cboData.length === 0 && !gettingUsers && <h3 className='nodata-placeholder' style={{
                        marginTop: 'calc(40vh - 50px)'
                    }}>No CBOs found</h3>}
                    </div>
                </div>
                <div className='col-9'>
                    <div className='card p-4 container-info'>
                    {selectedCBO.ID === undefined && <h3 className='nodata-placeholder'>Select a CBO to view details</h3>}
                    {selectedCBO.ID && <>
                        < div className='d-flex mb-2'>
                            <div className='me-auto'>
                                <div className='d-flex'>
                                    <h5 className='me-auto'>{selectedCBO.FULLNAME}</h5>
                                    <h6 className='pt-1 ms-2'>
                                        {selectedCBO.USER_STATUS === 'ACTIVE' && <MDBBadge pill color='info' className='me-auto'>{selectedCBO.USER_STATUS}</MDBBadge>}
                                        {selectedCBO.USER_STATUS !== 'ACTIVE' && <MDBBadge pill color='danger' className='me-auto'>{selectedCBO.USER_STATUS}</MDBBadge>}
                                    </h6>
                                </div>
                            </div>
                            <div className='ms-auto'>
                                <MDBBtn color='danger' size='sm' className='me-1' disabled={gettingUserAccess} onClick={sohwAsUser}>
                                    {gettingUserAccess && <MDBIcon fas icon="spinner" className='fa-spin' />}
                                    View As User
                                    </MDBBtn>
                            </div>
                        </div>

                        <div className='d-flex justify-content-between' style={{
                            height: 'calc(100vh - 190px)',
                            overflowY: 'auto',
                        }}>
                            <MDBTable hover bordered>
                                <MDBTableBody>
                                    {/* <tr>
                                        <th scope='row' className='py-2 w-30'>
                                            <strong>USER TYPE</strong>
                                        </th>
                                        <td className='pl-3 py-2 pl-4'> {selectedCBO.USER_TYPE}</td>
                                    </tr> */}
                                    <tr>
                                        <th scope='row' className='py-2'>
                                            <strong>EMAIL</strong>
                                        </th>
                                        <td className='pl-3 py-2 pl-4'> {selectedCBO.EMAIL?
                                            <a target='new' href={`mailto:${selectedCBO.EMAIL}`}>{selectedCBO.EMAIL}</a> : ''
                                        }</td>
                                    </tr>
                                    <tr>
                                        <th scope='row' className='py-2'>
                                            <strong>PHONE</strong>
                                        </th>
                                        <td className='pl-3 py-2 pl-4'> {selectedCBO.PHONE}</td>
                                    </tr>
                                    {/* <tr>
                                        <th scope='row' className='py-2'>
                                            <strong>COMUNITY ID</strong>
                                        </th>
                                        <td className='pl-3 py-2 pl-4'> {selectedCBO.COMUNITY_ID}</td>
                                    </tr> */}
                                    <tr>
                                        <th scope='row' className='py-2'>
                                            <strong>COMUNITY NAME</strong>
                                        </th>
                                        <td className='pl-3 py-2 pl-4'> {selectedCBO.COMUNITY_NAME}</td>
                                    </tr>
                                    <tr>
                                        <th scope='row' className='py-2'>
                                            <strong>CBO NAME</strong>
                                        </th>
                                        <td className='pl-3 py-2 pl-4'> {selectedCBO.CBO_NAME}</td>
                                    </tr>
                                    <tr>
                                        <th scope='row' className='py-2'>
                                            <strong>CBO TYPE</strong>
                                        </th>
                                        <td className='pl-3 py-2 pl-4'> {selectedCBO.CBO_TYPE}</td>
                                    </tr>
                                    <tr>
                                        <th scope='row' className='py-2'>
                                            <strong>CBO ADDRESS</strong>
                                        </th>
                                        <td className='pl-3 py-2 pl-4'> {selectedCBO.CBO_ADDRESS}</td>
                                    </tr>
                                    <tr>
                                        <th scope='row' className='py-2'>
                                            <strong>ZIPCODE</strong>
                                        </th>
                                        <td className='pl-3 py-2 pl-4'> {selectedCBO.ZIPCODE}</td>
                                    </tr>
                                    <tr>
                                        <th scope='row' className='py-2'>
                                            <strong>BUSINESS EIN</strong>
                                        </th>
                                        <td className='pl-3 py-2 pl-4'> {selectedCBO.BUSINESS_EIN}</td>
                                    </tr>
                                    <tr>
                                        <th scope='row' className='py-2'>
                                            <strong>IS CBO_RIGISTERED</strong>
                                        </th>
                                        <td className='pl-3 py-2 pl-4'> {selectedCBO.IS_CBO_RIGISTERED ?
                                            <MDBBadge color='success' light pill>Yes</MDBBadge>
                                            : <MDBBadge color='danger' light pill>No</MDBBadge>}</td>
                                    </tr>
                                    <tr>
                                        <th scope='row' className='py-2'>
                                            <strong>AUTHORIZED REPRESENTATIVE</strong>
                                        </th>
                                        <td className='pl-3 py-2 pl-4'> {selectedCBO.AUTHORIZED_REPRESENTATIVE}</td>
                                    </tr>
                                    <tr>
                                        <th scope='row' className='py-2'>
                                            <strong>CBO WEBSITE</strong>
                                        </th>
                                        <td className='pl-3 py-2 pl-4'> {selectedCBO.CBO_WEBSITE ? <a target='new' href={selectedCBO.CBO_WEBSITE}>{selectedCBO.CBO_WEBSITE}</a> : ''}</td>
                                    </tr>
                                    <tr>
                                        <th scope='row' className='py-2'>
                                            <strong>HAS EIN_PLAN</strong>
                                        </th>
                                        <td className='pl-3 py-2 pl-4'> {selectedCBO.HAS_EIN_PLAN ?
                                            <MDBBadge color='success' light pill>Yes</MDBBadge>
                                            : <MDBBadge color='danger' light pill>No</MDBBadge>}</td>
                                    </tr>
                                    <tr>
                                        <th scope='row' className='py-2'>
                                            <strong>EIN TIMEFRAME</strong>
                                        </th>
                                        <td className='pl-3 py-2 pl-4'> {selectedCBO.EIN_TIMEFRAME}</td>
                                    </tr>
                                    <tr>
                                        <th scope='row' className='py-2'>
                                            <strong>POSITION</strong>
                                        </th>
                                        <td className='pl-3 py-2 pl-4'> {selectedCBO.POSITION}</td>
                                    </tr>
                                    <tr>
                                        <th scope='row' className='py-2'>
                                            <strong>BOUNDRY MAP</strong>
                                        </th>
                                        <td className='pl-3 py-2 pl-4'> {selectedCBO.BOUNDRY_MAP}</td>
                                    </tr>
                                    {/* <tr>
                                        <th scope='row' className='py-2'>
                                            <strong>LOGIN STATUS</strong>
                                        </th>
                                        <td className='pl-3 py-2 pl-4'> {selectedCBO.LOGIN_STATUS}</td>
                                    </tr>
                                    <tr>
                                        <th scope='row' className='py-2'>
                                            <strong>LAST LOGIN</strong>
                                        </th>
                                        <td className='pl-3 py-2 pl-4'>: {getDateTime(selectedCBO.LAST_LOGIN)}</td>
                                    </tr>
                                    <tr>
                                        <th scope='row' className='py-2'>
                                            <strong>CREATED AT</strong>
                                        </th>
                                        <td className='pl-3 py-2 pl-4'>: {getDateTime(selectedCBO.CREATED_AT)}</td>
                                    </tr>
                                    <tr>
                                        <th scope='row' className='py-2'>
                                            <strong>UPDATED AT</strong>
                                        </th>
                                        <td className='pl-3 py-2 pl-4'>: {getDateTime(selectedCBO.UPDATED_AT)}</td>
                                    </tr> */}

                                </MDBTableBody>
                            </MDBTable>
                        </div>
                    </>}
                    </div>
                </div>
            </div >
        </>
    )
}

// export default withAITracking(reactPlugin, Dashboard);

export default Dashboard;
